<!-- =========================================================================================
    File Name: CardBasic.vue
    Description: Basic Cards
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="demo-basic-card">
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 lg:w-1/3 mb-base">
        <vx-card>
          <div slot="no-body">
            <img :src="card_1.img" alt="content-img" class="responsive card-img-top" />
          </div>
          <h5 class="mb-2">{{ card_1.title }}</h5>
          <p class="text-black">{{ card_1.subtitle }}</p>
          <p class="text-grey">{{ card_1.subtitle_2 }}</p>
          <div class="flex justify-between flex-wrap">
            <vs-button
              class="mt-4 mr-2 shadow-lg"
              type="gradient"
              color="#7367F0"
              gradient-color-secondary="#CE9FFC"
            >Download</vs-button>
            <vs-button class="mt-4" type="border" color="#b9b9b9">View All</vs-button>
          </div>
          <vs-divider></vs-divider>
          <div class="flex justify-between flex-wrap">
            <span>
              <p class="text-grey">Duration</p>
              <p class="text-xl">4 hours</p>
            </span>
            <span>
              <p class="text-grey">{{ card_2.footer_text_right_label }}</p>
              <p class="text-xl">{{ card_2.footer_text_right_value }}</p>
            </span>
          </div>
          <vs-divider></vs-divider>
          <div class="flex justify-between flex-wrap">
            <span>
              <p class="text-xl">{{ card_2.footer_text_left_value }}</p>
              <p class="text-grey">{{ card_2.footer_text_left_label }}</p>
            </span>
            <span>
              <p class="text-xl">{{ card_2.footer_text_right_value }}</p>
              <p class="text-grey">{{ card_2.footer_text_right_label }}</p>
            </span>
          </div>
          <vs-divider></vs-divider>
          <div class="flex justify-between text-center">
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_1_title }}</p>
              <small class="text-grey">{{ card_5.user_meta_1_label }}</small>
            </span>
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_2_title }}</p>
              <small class="text-grey">{{ card_5.user_meta_2_label }}</small>
            </span>
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_3_title }}</p>
              <small class="text-grey">{{ card_5.user_meta_3_label }}</small>
            </span>
          </div>
          <template slot="footer">
            <vs-divider />
            <div class="flex justify-between">
              <span class="flex items-center">
                <feather-icon
                  :icon="card_4.footer_text_1_icon"
                  svgClasses="h-5 w-5 text-warning stroke-current"
                />
                <span class="ml-2">{{ card_4.footer_text_1 }}</span>
              </span>
              <span class="flex items-center">
                <feather-icon
                  :icon="card_4.footer_text_2_icon"
                  svgClasses="h-5 w-5 text-primary stroke-current"
                />
                <span class="ml-2">{{ card_4.footer_text_2 }}</span>
              </span>
            </div>
          </template>
          <div class="mb-4 mt-base">
            <div class="flex justify-between">
              <small class="font-semibold">{{ card_6.current_progress }}</small>
              <small class="text-grey">{{ card_6.progress_goal }}</small>
            </div>
            <vs-progress :percent="card_6.progress_done_per" class="block mt-1 shadow-md"></vs-progress>
          </div>
          <div class="flex justify-between flex-wrap">
            <vs-button
              class="mt-4 mr-2 shadow-lg"
              type="gradient"
              color="#7367F0"
              gradient-color-secondary="#CE9FFC"
            >{{ card_6.btn_left_text }}</vs-button>
            <vs-button class="mt-4" type="border" color="#b9b9b9">{{ card_6.btn_right_text }}</vs-button>
          </div>
        </vx-card>
      </div>

      <div class="vx-col w-full lg:w-1/3 sm:w-1/2 mb-base">
        <vx-card class="p-2">
          <div class="text">
            <h4>Pricing details</h4>
            <p class="text-grey">{{ card_5.profession }}</p>
          </div>
          <vs-avatar class="mx-auto my-6 block" size="80px" :src="card_5.userImg" />
          <vs-divider></vs-divider>

          <div class="flex justify-between flex-wrap">
            <span>
              <p class="text-grey">Total</p>
              <p class="text-xl">{{ card_2.footer_text_left_value }}</p>
            </span>
            <span>
              <p class="text-grey">{{ card_2.footer_text_right_label }}</p>
              <p class="text-xl">{{ card_2.footer_text_right_value }}</p>
            </span>
          </div>
          <vs-divider></vs-divider>
          <div class="flex justify-between text-center">
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_1_title }}</p>
              <small class="text-grey">Consultation</small>
            </span>
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_2_title }}</p>
              <small class="text-grey">Imaging</small>
            </span>
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_3_title }}</p>
              <small class="text-grey">Laboratory</small>
            </span>
          </div>
          <vs-divider></vs-divider>
          <div class="text">
            <h4>Key Risks Factors</h4>
            <p class="text-grey">{{ card_5.profession }}</p>
          </div>
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >R1</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >R2</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >R3</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >R4</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >R5</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >R6</vs-button>
&nbsp;
          <vs-divider></vs-divider>
          <div class="text">
            <h4>Centers</h4>
            <p class="text-grey">{{ card_5.profession }}</p>
          </div>
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >A</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >B</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >C</vs-button>
&nbsp;
          <vs-divider></vs-divider>
          <div class="text">
            <h4>Competitors</h4>
            <p class="text-grey">{{ card_5.profession }}</p>
          </div>
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >A</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >B</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >C</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >D</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >E</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >F</vs-button>&nbsp;
        </vx-card>
      </div>

      <div class="vx-col w-full lg:w-1/3 sm:w-1/2 mb-base">
        <vx-card class="p-2">
          <div class="text">
            <h4>Outcome, cost, revenues</h4>
            <p class="text-grey">{{ card_5.profession }}</p>
          </div>
          <vs-avatar class="mx-auto my-6 block" size="80px" :src="card_5.userImg" />
          <vs-divider></vs-divider>
          <div class="flex justify-between flex-wrap">
            <span>
              <p class="text-grey">Revenue</p>
              <p class="text-xl">{{ card_2.footer_text_left_value }}</p>
            </span>
            <span>
              <p class="text-grey">Margin</p>
              <p class="text-xl">10%</p>
            </span>
            <span>
              <p class="text-grey">EBITDA</p>
              <p class="text-xl">20%</p>
            </span>
          </div>
          <vs-divider></vs-divider>
          <div class="flex justify-between text-center">
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_1_title }}</p>
              <small class="text-grey">customers</small>
            </span>
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_2_title }}</p>
              <small class="text-grey">{{ card_5.user_meta_2_label }}</small>
            </span>
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_3_title }}</p>
              <small class="text-grey">Avg. TRT</small>
            </span>
            <span>
              <p class="text-xl font-semibold">{{ card_5.user_meta_3_title }}</p>
              <small class="text-grey">Avg. TAT</small>
            </span>
          </div>
          <vs-divider></vs-divider>
          <div class="text">
            <h4>Care Plan</h4>
            <p class="text-grey">{{ card_5.profession }}</p>
          </div>
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >A</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >B</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >C</vs-button>
&nbsp;
          <vs-divider></vs-divider>
          <div class="text">
            <h4>Care Team</h4>
            <p class="text-grey">{{ card_5.profession }}</p>
          </div>
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >3 Doctors</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >1 Nurse</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >1 Radiographer</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >D</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >E</vs-button>&nbsp;
          <vs-button
            type="gradient"
            class="mt-2"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >F</vs-button>&nbsp;
        </vx-card>
      </div>

      <div class="vx-col w-full lg:w-1/3 sm:w-1/2 mb-base">
        <vx-card class="p-2">
          <div class="text-center">
            <h4>{{ card_5.name }}</h4>
            <p class="text-grey">{{ card_5.profession }}</p>
          </div>
          <vs-avatar class="mx-auto my-6 block" size="80px" :src="card_5.userImg" />

          <vs-button
            type="gradient"
            class="w-full mt-6"
            color="#7367F0"
            gradient-color-secondary="#CE9FFC"
          >{{ card_5.btn_text }}</vs-button>
        </vx-card>
      </div>
      <!-- USER PRODFILE BASIC CARD

            <div class="vx-col w-full lg:w-1/3 sm:w-1/2 mb-base">
                <vx-card class="p-2">
                    <vs-avatar class="mx-auto mb-6 block" size="80px" :src="card_6.userImg" />
                    <div class="text-center">
                        <h4>{{ card_6.name }}</h4>
                        <p class="text-grey">{{ card_6.profession }}</p>
                    </div>
                </vx-card>
      </div>-->

      <!-- ACTIVITY TIMELINE -->
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 mb-base">
                <vx-card title="Activity Timeline">
                    <vx-timeline :data="timelineData"></vx-timeline>
                </vx-card>
      </div>
    </div>


      <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
        <vx-card class="p-2">
          <div class="text-left">
            <h4>{{ card_5.name }}</h4>
            <p class="text-grey">{{ card_5.profession }}</p>
          </div>
          <div class="text-right">
            <vs-button class="mt-4" type="border" color="#7367F0">Update</vs-button>
        </div>

        </vx-card>
      </div>

    <!-- CARD WITH TABS COMPONENT -->
    <div class="vx-row match-height">
      <!-- SIMPLE CARD WITH TABS -->
      <div class="vx-col w-full md:w-1/1 mb-base">
        <vx-card>
          <div>
            <vs-navbar v-model="activeItem" class="nabarx">
              <div slot="title">
                <vs-navbar-title>HealthMinder</vs-navbar-title>
              </div>

              <vs-navbar-item index="0">
                <a href="#">Help</a>
              </vs-navbar-item>
            </vs-navbar>
          </div>

          <p class="text-black">Edited: {{ card_1.subtitle }}</p>
          <br />
          <p class="text-black">Hi {{ card_1.subtitle }}</p>
          <p class="text-black">Here is a new package card for your review and approval</p>

          <vs-tabs>
            <vs-tab vs-label="Summary(57)">
              <div class="mt-3">
                <p>1) Lollipop chocolate cake muffin pudding biscuit cheesecake. Pudding biscuit caramels jelly beans caramels. Pie macaroon wafer danish sweet caramels. Icing jelly-o cotton candy sweet roll brownie marshmallow marzipan icing. Candy canes apple pie icing. Tart macaroon pastry caramels chocolate bar. Gummies cupcake sesame snaps chocolate cookie sugar plum bear claw. Cheesecake chocolate cake dessert bear claw candy tiramisu soufflé.</p>
                <p>2) Lollipop chocolate cake muffin pudding biscuit cheesecake. Pudding biscuit caramels jelly beans caramels. Pie macaroon wafer danish sweet caramels. Icing jelly-o cotton candy sweet roll brownie marshmallow marzipan icing. Candy canes apple pie icing. Tart macaroon pastry caramels chocolate bar. Gummies cupcake sesame snaps chocolate cookie sugar plum bear claw. Cheesecake chocolate cake dessert bear claw candy tiramisu soufflé.</p>
                <p>3) Lollipop chocolate cake muffin pudding biscuit cheesecake. Pudding biscuit caramels jelly beans caramels. Pie macaroon wafer danish sweet caramels. Icing jelly-o cotton candy sweet roll brownie marshmallow marzipan icing. Candy canes apple pie icing. Tart macaroon pastry caramels chocolate bar. Gummies cupcake sesame snaps chocolate cookie sugar plum bear claw. Cheesecake chocolate cake dessert bear claw candy tiramisu soufflé.</p>
              </div>
              <vs-textarea label="Message0" v-model="msg" />
            </vs-tab>
            <vs-tab vs-label="Consultation(5)">
              <div class="mt-3">
                <p>Wafer sesame snaps toffee brownie liquorice danish icing fruitcake croissant. Gingerbread chocolate cake danish pie gingerbread. Muffin donut fruitcake powder jelly lemon drops cheesecake. Croissant oat cake carrot cake tiramisu halvah. Cupcake cupcake wafer muffin topping danish cheesecake croissant. Liquorice donut liquorice lollipop sesame snaps lollipop ice cream macaroon danish. Cupcake chocolate cake chupa chups gummi bears macaroon.</p>
              </div>
              <vs-textarea label="Message1" v-model="msg" />
            </vs-tab>
            <vs-tab vs-label="Imaging">
              <div class="mt-3">
                <p>Cookie cheesecake fruitcake sweet roll gummi bears marzipan marshmallow marshmallow. Cheesecake muffin jujubes chocolate cake carrot cake jujubes croissant. Cake toffee carrot cake topping oat cake lemon drops toffee toffee marzipan. Gingerbread toffee marshmallow marshmallow caramels brownie donut cake. Cheesecake candy topping tart cotton candy chocolate bar jujubes powder chupa chups. Cupcake candy soufflé jelly beans. Icing lollipop tiramisu oat cake dessert.</p>
              </div>
              <vs-textarea label="Message2" v-model="msg" />
            </vs-tab>
            <vs-tab vs-label="Market Insights">
              <div class="mt-3">
                <p>Cookie cheesecake fruitcake sweet roll gummi bears marzipan marshmallow marshmallow. Cheesecake muffin jujubes chocolate cake carrot cake jujubes croissant. Cake toffee carrot cake topping oat cake lemon drops toffee toffee marzipan. Gingerbread toffee marshmallow marshmallow caramels brownie donut cake. Cheesecake candy topping tart cotton candy chocolate bar jujubes powder chupa chups. Cupcake candy soufflé jelly beans. Icing lollipop tiramisu oat cake dessert.</p>
              </div>
              <vs-textarea label="Message2" v-model="msg" />
            </vs-tab>
          </vs-tabs>

          <div class="flex justify-between flex-wrap">
            <vs-button
              class="mt-4 mr-2 shadow-lg"
              type="gradient"
              color="#7367F0"
              gradient-color-secondary="#CE9FFC"
            >Approve</vs-button>
            <vs-button class="mt-4" type="border" color="#b9b9b9">Reject</vs-button>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios.js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { videoPlayer } from "vue-video-player";
import VxTimeline from "@/components/timeline/VxTimeline.vue";
import "video.js/dist/video-js.css";

export default {
  components: {
    VuePerfectScrollbar,
    videoPlayer,
    VxTimeline
  },
  data() {
    return {
      // card 1
      card_1: {},
      card_2: {},
      card_3: {},
      card_4: {},
      card_5: {},
      card_6: {},
      card_7: {},
      card_8: {},

      chatLog: [],
      chatMsgInput: "",

      card_9: {},

      timelineData: [
        {
          color: "primary",
          icon: "PlusIcon",
          title: "New Task Added",
          desc: "Bonbon macaroon jelly beans gummi bears jelly lollipop apple",
          time: "25 Days Ago"
        },
        {
          color: "warning",
          icon: "AlertCircleIcon",
          title: "Task Update Found",
          desc: "Cupcake gummi bears soufflé caramels candy",
          time: "15 Days Ago"
        },
        {
          color: "success",
          icon: "CheckIcon",
          title: "Task Finished",
          desc: "Candy ice cream cake. Halvah gummi bears",
          time: "20 mins ago"
        }
      ],

      name: "",
      email: "",
      msg: "",

      playerOptions: {},
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    }
  },
  mounted() {
    const scroll_el = this.$refs.chatLogPS.$el || this.$refs.chatLogPS;
    scroll_el.scrollTop = this.$refs.chatLog.scrollHeight;
  },
  created() {
    const HMinderEndPoint = "http://localhost:8090/";

    var config = {
      //headers: {'Access-Control-Allow-Origin': '*'}
      headers: {
        "Access-Control-Allow-Origin": "*"
      }
    };
    axios.get(HMinderEndPoint + "packg/getAll", config).then(response => {
      console.log("packgs");
      console.log(response.data);
      this.healthpackages = response.data.packgs;

      console.log("ServiceType");
     // const Property = "type";
      //const playersByTeam = _.groupBy(response.data.packgs[0].serviceRequests, Property);
      //this.serviceTypes = playersByTeam;

      //console.log(this.serviceTypes);
    });
    // Card 1
    this.$http
      .get("/api/users/pixinvent/product/1")

      .then(response => {
        this.card_1 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 2
    this.$http
      .get("/api/users/pixinvent/product/sales/1")
      .then(response => {
        this.card_2 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 3
    this.$http
      .get("/api/users/pixinvent/product/goals/1")
      .then(response => {
        this.card_3 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 4
    this.$http
      .get("/api/users/89")
      .then(response => {
        this.card_4 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 5
    this.$http
      .get("/api/users/58")
      .then(response => {
        this.card_5 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 6
    this.$http
      .get("/api/users/16")
      .then(response => {
        this.card_6 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 7
    this.$http
      .get("/api/place/18")
      .then(response => {
        this.card_7 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 8
    this.$http
      .get("/api/place/76/weather")
      .then(response => {
        this.card_8 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Chat Log
    this.$http
      .get("/api/chat/demo-1/log")
      .then(response => {
        this.chatLog = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Card 9
    this.$http
      .get("/api/item/12/review")
      .then(response => {
        this.card_9 = response.data;
      })
      .catch(error => {
        console.log(error);
      });

    // Player Options
    this.$http
      .get("/api/card/card-basic/player-option")
      .then(response => {
        this.playerOptions = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style lang="scss">
#demo-basic-card {
  .overlay-card {
    .vx-card__collapsible-content {
      max-height: 485px;
    }
  }

  .chat-card-log {
    height: 360px;

    .chat-sent-msg {
      background-color: #f2f4f7 !important;
    }
  }

  .card-video {
    .video-js {
      height: 370px;
    }
  }
}
</style>
